import { PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import { Header, Layout, Root } from '../components';
import { Tournament } from '../old/components';
import { Page } from './page';

export function getJsonFromParams<T>(params: string) {
  return params
    .split('&')
    .map((part) => part.split('='))
    .reduce(
      (obj: Partial<T>, part) => ({
        [part[0]]: decodeURIComponent(part[1] || ''),
        ...obj,
      }),
      {},
    );
}

const TournamentTemplate: React.FC<PageProps<null, { pages: Page[] }>> = ({
  pageContext: { pages },
}) => {
  const params =
    typeof window !== 'undefined'
      ? getJsonFromParams<{ turnering: string; grupp: string }>(
          location.search.substr(1),
        )
      : {};

  return (
    <>
      <Helmet>
        <title>Yes2Chess - Turnering</title>
      </Helmet>
      <Root>
        <Layout>
          <Header pages={pages}>
            <Tournament
              tournamentId={+(params.turnering || 0)}
              groupId={+(params.grupp || 0)}
              round={+(params.rond || 0)}
            />
          </Header>
        </Layout>
      </Root>
    </>
  );
};

export default TournamentTemplate;
